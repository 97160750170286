:root {
  --clr-primary: #0099d9;
  --clr-secondary: #ed3338;
  --clr-third: #f58733;
  --clr-fourth: #57ba52;
  --clr-text: #2d2d2d;
  --clr-white: #fff;
  --clr-dark: #1b1c1e;
  --clr-dark-one: #1f1f1f;
  --clr-dark-two: #2b2b2b;
  --clr-dark-three: #303030;
  --clr-neutral-900: hsl(207, 19%, 9%);
}

html {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-rendering: auto;
  -moz-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jodit-workplace{
  height: 300px !important;
}